<template>
  <div class="box" style="height: calc(100vh - 110px);">
    <div class=" ">
      <div class="flex py-3 mb-4">
        <i :class="`pi pi-angle-left my-auto cursor-pointer mx-2 my-auto`" style="font-size: 1.4rem" @click="router.back()"></i>
        <i :class="`pi pi-align-right mx-2 my-auto`" style="font-size: 1rem" ></i>
        <b class="my-auto">Documentos Inactivos</b>
      </div>
      <DataTable :value="listArchivos" class="p-datatable-sm" :rowHover="true"  showGridlines responsiveLayout="scroll">
          <Column field="id" header="ID" style="min-width:130px"></Column>
          <Column header="Nombre" style="min-width:130px">
            <template #body="{data}">
              <p style="overflow-wrap: break-word">{{ data.nombre }}</p>
            </template>
          </Column>
          <!-- <Column header="Version" style="min-width:180px">
              <template #body="{data}">
              <p style="overflow-wrap: break-word">{{data.version}}</p>
              </template>
          </Column> -->
          <Column header="Fecha Eliminacion" style="min-width:180px">
            <template #body="{data}">
              <p style="overflow-wrap: break-word">{{ data.deletedAt.substring(0, 10)}}</p>
            </template>
          </Column>
          <Column header="Acciones" style="min-width:180px">
            <template #body="{data}">
              <div :key="key" class="flex p-2 bg-item rounded-lg my-1">
                <i
                :class="`pi pi-${ data.tipo === 'pdf' || data.tipo === 'txt' ? 'file-pdf text-red-400': 'file-excel text-green-400'} my-auto mx-2 cursor-pointer`"
                style="font-size: 1rem"
                @click="displayViewDoc = true, getArchivoPath(data, carpetas)"
                ></i>
              </div>
          </template>
          </Column>
      </DataTable>
    </div>
  </div>
</template>
<script>

import { onMounted, ref, onBeforeMount } from 'vue'
import CarpetasServices from './services/get.service'
import { useRoute, useRouter } from 'vue-router'
import store from './store'
import ModalSolicitudesService from '@/apps/pharmasan/calidad/views/mapa/services/modalSolicitudes.service'
export default {
  name: 'CalidadInactivos',
  components: {
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const _Get = ref(new CarpetasServices())
    const _ModalSolictudesService = ref(new ModalSolicitudesService())
    const listArchivos = ref([])
    const filePath = ref('')
    const carpetas = ref([])
    const displayAddDoc = ref(false)
    const displayViewDoc = ref(false)
    const tipoView = ref('pdf')

    const ListarInactivos = (event) => {
      _Get.value.getInactivos().then(({ data }) => {
          console.log('getInactivos-', data)
          listArchivos.value = data
        }).catch(err => {
        console.log(err.message)
        })
      }

    const getArchivos = () => {
      _Get.value.getArchivo(route.params.id).then(({ data }) => {
            listArchivos.value = data
        }).catch(err => {
          console.log(err.message)
        })
    }

    const getArchivoPath = (file) => {
      _Get.value.getArchivoPath(file.id).then(({ data }) => {
          file.path = data
          viewPdf(file)
        }).catch(err => {
          console.log(err.message)
        })
    }
    const viewPdf = (file) => {
      // console.log('el path-view---', file)
      // console.log('el path-view---', doc)
      tipoView.value = file.tipo
      var arraynombre = file.nombre.split(' ')
      var dataHtml = {
          path: file.path,
          tipo: file.tipo,
          cod: file.cod ? file.cod : arraynombre.shift(),
          nombreArchivo: file.nombre ? file.nombre : arraynombre.join(' '),
          version: file.version,
          createdAt: file.createdAt.substring(0, 10),
          updatedAt: file.updatedAt.substring(0, 10)
        }
        if (file.tipo === 'pdf') {
            const url = _ModalSolictudesService.value.getUri({ path: file.path, tipo: file.tipo })
            // console.log('url------', url)
            if (file.tipo === 'pdf' || file.tipo === 'txt') {
                    filePath.value = url.base ? url.base : url
                store.dispatch('updateTipoView', 'pdf')
                } else {
                window.open(url, '_blank')
                }
        } else {
            _ModalSolictudesService.value.getHtml(dataHtml).then(({ data }) => {
                console.log('viewPdf------', data)
                if (file.tipo === 'txt') {
                //   filePath.value = data
                    filePath.value = data.base ? data.base : data
                //   props.tipoView.value = 'pdf'
                    store.dispatch('updateTipoView', 'pdf')
                } else {
                  window.open(data, '_blank')
                }
            }).catch(err => {
              console.log(err.message)
            })
        }
    }

    onBeforeMount(() => {
      // getArchivos()
    })

    onMounted(() => {
      ListarInactivos()
    })
    return {
      listArchivos,
      filePath,
      router,
      route,
      carpetas,
      displayAddDoc,
      displayViewDoc,
      tipoView,
      getArchivos,
      getArchivoPath
    }
  }
}
</script>
<style lang="scss">
.bg-grad{
  background: transparent linear-gradient(1deg, #0080F9 0%, #00DCB1 100%) 0% 0% no-repeat padding-box;
}
.w-proceso{
  width: 30%;
}
</style>
